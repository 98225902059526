import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear user data
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');

        // Notify other components that user has logged out
        window.dispatchEvent(new Event("userLoggedOut"));

        // Redirect to login page
        navigate('/login');
    }, [navigate]);

    return null; // No UI needed
};

export default Logout;
