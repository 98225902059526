import { useState, useEffect } from "react";
import api from "../Component/axiosConfig"; // Use your api instance

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        // Validate token via API if required
        const response = await api.get("/api/validate-token/", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
          setUser(response.data.user);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication check failed", error);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }

      setLoading(false);
    };

    checkAuth();
  }, []);

  return { isAuthenticated, loading, user };
};

export default useAuth;
