import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

import './Categories.css';
import api from '../axiosConfig'; // Import the Axios instance

function Categories() {
      const [categories, setCategories] = useState([]);

      useEffect(() => {
            // Fetch categories from the API
            // .get('https://ebaazar.sanichar.online/api/categories/')

            api
                  .get('api/categories/')
                  .then(response => {
                        setCategories(response.data);
                  })
                  .catch(error => {
                        console.error('Error fetching categories:', error);
                  });
      }, []);

      // Recursive function to render categories and their subcategories
      const renderCategories = (categories) => {
            return categories.map(category => (
                  <div className="dropdown-submenu" key={category.id}>
                        <Link to={`/cat/${category.slug}`} className="dropdown-item">
                              {category.name}
                        </Link>
                        {category.subcategories && category.subcategories.length > 0 && (
                              <div className="dropdown-menu">
                                    {renderCategories(category.subcategories)}
                              </div>
                        )}
                  </div>
            ));
      };

      return (
            <div className='tee container-fluid mt-4'>
                  <div className="container-fluid category-nav">
                        {categories.map(category => (
                              //     <div key={category.id} className="category-container">
                              <Link to={`/cat/${category.slug}`} key={category.id} className="level-same">
                                    <div className='category-img'>
                                          <img
                                                src={category.image_url}
                                                alt={category.name}
                                                className="img-fluid"

                                          />
                                    </div>

                                    {category.subcategories && category.subcategories.length > 0 && (
                                          <>

                                                <Link
                                                      className="dropdown-toggle nav-link"
                                                      to={`/cat/${category.slug}`}
                                                      role="button"
                                                      id={`dropdownMenuLink-${category.id}`}
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                      key={category.id}
                                                >
                                                      {category.name}
                                                </Link>
                                                <div
                                                      className="dropdown-menu"
                                                      aria-labelledby={`dropdownMenuLink-${category.id}`}
                                                >
                                                      {renderCategories(category.subcategories)}
                                                </div>

                                          </>
                                    ) || (
                                                <Link to={`/cat/${category.slug}`} className="nav-link">{category.name}</Link>
                                          )
                                    }
                                    {/* </div> */}
                              </Link>
                        ))}
                  </div>
            </div>
      );
}

export default Categories;
