import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Nav.css';
import MobileNavbar from './MobileNavbar';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState(null);

  // Function to load user data
  const loadUser = () => {
    const storedUser = localStorage.getItem('user');
    setUser(storedUser ? JSON.parse(storedUser) : null);
  };

  // Load user data when component mounts
  useEffect(() => {
    loadUser();

    // Listen for login/logout updates from the login function
    const handleUserUpdate = () => {
      loadUser(); // Reload user from localStorage
    };

    // Listen for login/logout events
    window.addEventListener('userLoggedIn', handleUserUpdate);
    window.addEventListener('userLoggedOut', handleUserUpdate);

    return () => {
      window.removeEventListener('userLoggedIn', handleUserUpdate);
      window.removeEventListener('userLoggedOut', handleUserUpdate);
    };
  }, []);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    setUser(null);

    // Dispatch event to notify other components (Navbar) about logout
    window.dispatchEvent(new Event('userLoggedOut'));

    navigate('/login');
  };

  // List of routes where the search bar should not be displayed
  const hideSearchBarRoutes = [
    '/contact', '/about', '/cart', '/privacy', '/shipping', '/faq',
    '/security', '/terms', '/becomeseller'
  ];

  // Routes where the navbar should be completely hidden
  const hideNavbarRoutes = ['/becomeseller'];

  if (hideNavbarRoutes.includes(location.pathname)) {
    return null;
  }

  const navbarClass = location.pathname === '/' ? 'navbar-white' : 'navbar-blue';

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search/?q=${searchQuery}`);
    }
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${navbarClass} ${hideSearchBarRoutes.includes(location.pathname) ? 'no-search' : ''}`}>
        <div className='mobile-left-menu'>
          <MobileNavbar />
          <Link className="navbar-brand" to="/">Ebazaar</Link>
        </div>

        {!hideSearchBarRoutes.includes(location.pathname) && (
          <form className="d-flex has-search w-100 d-lg-block d-none" onSubmit={handleSearchSubmit}>
            <span className="fa fa-search form-control-feedback"></span>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search for Products, Brands and More"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        )}

        <ul className="nav nav-right">
          <li className="nav-item hover-item">
            <div
              className="dropdown dropAnimation"
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              style={{ cursor: 'pointer' }}
            >
              <div id="dropdownMenuButton1" aria-expanded={isOpen}>
                <i className="fa-regular fa-circle-user"></i> {user ? user.first_name : "Account"}
              </div>
              {isOpen && (
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {user ? (
                    <>
                      <li>
                        <a className="dropdown-item" onClick={() => navigate('/account')}>
                          <i className="fa-regular fa-circle-user"></i> My Profile
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => navigate('/orderPage')}>
                          <i className="fa-solid fa-cart-plus"></i> Orders
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => navigate('/whishlist')}>
                          <i className="fa-regular fa-heart"></i> Wishlist
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" onClick={() => navigate('/gift_card')}>
                          <i className="fa-regular fa-credit-card"></i> Gift Cards
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item logout" onClick={handleLogout}>
                          <i className="fa-solid fa-sign-out-alt"></i> Logout
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <div className='drop-header'>
                        <div className='login-link'><Link className='link' to='/register'>New Customer?</Link></div>
                        <div className='register-link'><Link className='link' to='/login'>Sign Up</Link></div>
                      </div>
                    </>
                  )}
                </ul>
              )}
            </div>
          </li>

          <li className="nav-item cart-box">
            <div className='cart-add'>2</div>
            <div className="dropdown mid-cart cart" onClick={() => navigate('/cart')}>
              <div className="d-flex align-items-center">
                <i className="fa-solid fa-cart-shopping me-1"></i> <span className='d-none d-lg-block'> Cart</span>
              </div>
            </div>
          </li>
          <li className="nav-item d-flex align-items-center d-lg-block d-none" onClick={() => navigate('/becomeseller')}>
            <i className="fa-solid fa-box-archive"></i> <span>Become a seller</span>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
