import React, { useState, useEffect } from 'react';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import axiosConfig from '../axiosConfig';

function Profile() {
  const [isEditable, setIsEditable] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    phone: '',
    username: '',
    image_url: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch user profile data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setErrorMessage('User not authenticated.');
          return;
        }

        const response = await axiosConfig.get('/api/user/profile/', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserData(response.data);
      } catch (error) {
        setErrorMessage('Failed to fetch user data.');
      }
    };

    fetchUserData();
  }, []);

  // Toggle edit mode
  const handleEditClick = () => {
    if (isEditable) {
      handleUpdateProfile();
    }
    setIsEditable(!isEditable);
  };

  // Handle form input changes
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handle profile picture selection
  const handleFileChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  // Update user profile
  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append("first_name", userData.first_name);
      formData.append("last_name", userData.last_name);
      formData.append("gender", userData.gender);
      
      if (selectedImage) {
        formData.append("image", selectedImage);
      }

      await axiosConfig.put('/api/user/profile/', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Profile updated successfully!');
    } catch (error) {
      setErrorMessage('Failed to update profile.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h5" gutterBottom>Personal Information</Typography>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}

      <div>
        <img
          src={userData.image_url || "https://via.placeholder.com/150"}
          alt="Profile"
          style={{ width: 150, height: 150, borderRadius: '50%', marginBottom: '10px' }}
        />
        {isEditable && (
          <input type="file" accept="image/*" onChange={handleFileChange} />
        )}
      </div>

      <TextField
        label="First Name"
        name="first_name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userData.first_name}
        onChange={handleChange}
        disabled={!isEditable}
      />
      
      <TextField
        label="Last Name"
        name="last_name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userData.last_name}
        onChange={handleChange}
        disabled={!isEditable}
      />

      <RadioGroup row aria-label="gender" name="gender" value={userData.gender} onChange={handleChange}>
        <FormControlLabel value="male" control={<Radio disabled={!isEditable} />} label="Male" />
        <FormControlLabel value="female" control={<Radio disabled={!isEditable} />} label="Female" />
      </RadioGroup>

      <TextField
        label="Mobile"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userData.phone}
        disabled
      />

      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userData.email}
        disabled
      />

      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        value={userData.username}
        disabled
      />

      <Button variant="contained" onClick={handleEditClick}>
        {isEditable ? 'Save' : 'Edit'}
      </Button>
    </div>
  );
}

export default Profile;
