import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button } from '@mui/material';
import './Login.css';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import api from '../axiosConfig'; // Import the Axios instance


function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await api.post('/api/login/', {
        email,
        password
      });

      if (response.data.access_token) {
        setSuccessMessage('Login successful!');

        // Store tokens and user details in localStorage
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user details as JSON

        // Dispatch a custom event to notify Navbar
        window.dispatchEvent(new Event("userLoggedIn"));

        navigate('/'); // Redirect after successful login
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.error || 'Something went wrong');
      } else {
        setErrorMessage('Network error');
      }
    }
  };


  return (
    <>
      <HoverDropdown />
      <div className='section-margin-top-bottom'>
        <div className='login-Container'>
          <div className='login-form'>
            <div className='login-left-content'>
              <Typography variant="h5" color="white" className='mb-3'>Login</Typography>
              <p> Get access to your Orders,</p>
              <p>Wishlist and Recommendations</p>
              <img src="https://cdni.iconscout.com/illustration/premium/thumb/login-page-illustration-download-in-svg-png-gif-file-formats--app-developing-development-secure-mobile-webapp-and-pack-design-illustrations-3783954.png" alt="Login" className="bottom-image" />
            </div>
            <div className='login-right-content'>
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                variant="standard"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Typography className='small' variant="body1" align="center">
                {/* By Continuing, you agree to Ebazaar's <a href="#">Terms of Use</a> and <a href='#' onClick={PrivacyClick}>Privacy Policy.</a> */}
                By Continuing, you agree to Ebazaar's <a href="#">Terms of Use</a> and <a href='#'>Privacy Policy.</a>
              </Typography>
              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              {successMessage && (
                <Typography variant="body2" color="success" align="center">
                  {successMessage}
                </Typography>
              )}
              <Button variant="contained" className='btn-login' fullWidth onClick={handleLogin}>
                Login
              </Button>
              <div className='register-info'>
                <Typography variant="body2">
                  You don't have an account? <Link to="/register">Sign Up</Link>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
