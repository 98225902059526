import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import useAuth from "./hooks/useAuth"; // Import authentication hook
import ProtectedRoute from "./Component/ProtectedRoute"; // Import protected route component
import Navbar from './Component/Navbar/Navbar';
import Footer from './Component/Footer/Footer';
import Home from './Component/Home/Home';
import Register from './Component/Register/Register';
import ProductList from './Component/ProductList/ProductList';
import ProductDetails from './Component/ProductDetails/ProductDetails';
import Login from './Component/Login/Login';
import Logout from './Component/Logout/Logout';
import Cart from './Component/Cart/Cart';
import Account from './Component/Account/Account';
import MyOrder from './Component/MyOrder/MyOrder';
import Payment from './Component/pages/Payment';
import Shipping from './Component/pages/Shipping';
import CancellationRefund from './Component/pages/CancellationRefund';
import Faq from './Component/pages/Faq';
import ScrollToTop from './Component/PageScroll/ScrollToTop';
import PrivacyPolicy from './Component/pages/PrivacyPolicy';
import TermsAndConditions from './Component/pages/TermsAndConditions';
import Security from './Component/pages/Security';
import EPRCompliance from './Component/pages/EPRCompliance';
import ContactPage from './Component/Contact/ContactPage';
import About from './Component/About/About';
import NotificationPage from './Component/UserSupport/NotificationPage';
import CustomerSupportPage from './Component/UserSupport/CustomerSupportPage';
import WishlistPage from './Component/WishlistPage.js/WhishlistPage';
import GiftCardPage from './Component/GiftCard/GiftCardPage';
import BecomeSeller from './Component/BecomeSeller/BecomeSeller';
import MobileNavbar from './Component/Navbar/MobileNavbar';
import AllCategories from './Component/Categories/AllCategories';
import BecomeNavbar from './Component/BecomeSeller/BecomeNavbar';
import Loader from './Component/Loader/Loader';

// Helper component to trigger loader on route change
const RouteChangeLoader = ({ setLoading }) => {
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location, setLoading]);

  return null;
};

function App() {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth(); // Check if user is authenticated

  // Initial load loader simulation
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      <RouteChangeLoader setLoading={setLoading} />

      {loading && <Loader />}

      {!loading && (
        <>
          <Navbar />
          <ScrollToTop />
          <Routes>
            {/* ✅ Public Routes (No Authentication Required) */}
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/security" element={<Security />} />
            <Route path="/epr-compliance" element={<EPRCompliance />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/products/" element={<ProductList />} />
            <Route path="/search/" element={<ProductList />} />
            <Route path="/cat/:category_slug/" element={<ProductList />} />
            <Route path="/brand/:brand_slug/" element={<ProductList />} />
            <Route path="/product/:product_slug/" element={<ProductDetails />} />

            {/* ✅ Protected Routes (Require Authentication) */}
            <Route element={<ProtectedRoute />}>
              <Route path="/cart" element={<Cart />} />
              <Route path="/account" element={<Account />} />
              <Route path="/orderPage" element={<MyOrder />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/cancellation" element={<CancellationRefund />} />
              <Route path="/notification" element={<NotificationPage />} />
              <Route path="/customer_support" element={<CustomerSupportPage />} />
              <Route path="/wishlist" element={<WishlistPage />} />
              <Route path="/gift_card" element={<GiftCardPage />} />
              <Route path="/becomeseller" element={<BecomeSeller />} />
            </Route>

            {/* ✅ Catch-all 404 Route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
