import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import './ProductList.css';
import ProductFilter from '../ProductFilter/ProductFilter';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import AppFilter from '../AppFilter/AppFilter';
import api from '../axiosConfig'; // Import the Axios instance
import Loader from '../Loader/Loader'; // Import Loader

const ProductList = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { category_slug, brand_slug } = useParams();

    // Loader state
    const [loading, setLoading] = useState(true);

    // Extract query parameters
    const [filters, setFilters] = useState({
        keyword: searchParams.get('q') || '',
        priceFrom: searchParams.get('price_from') || '',
        priceTo: searchParams.get('price_to') || '',
        offer: searchParams.get('offer') || '',
        brand: searchParams.get('brand') || '',
        color: searchParams.get('color') || '',
        size: searchParams.get('size') || '',
        rating: searchParams.get('rating') || '',
    });

    const [products, setProducts] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [activeSort, setActiveSort] = useState('popularity');

    // Update filters when the query string changes
    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            keyword: searchParams.get('q') || '',
            priceFrom: searchParams.get('price_from') || '',
            priceTo: searchParams.get('price_to') || '',
            offer: searchParams.get('offer') || '',
            brand: searchParams.get('brand') || '',
            color: searchParams.get('color') || '',
            size: searchParams.get('size') || '',
            rating: searchParams.get('rating') || '',
        }));
    }, [searchParams]);

    // Build query string
    const buildQueryString = () => {
        const params = new URLSearchParams();
        for (const [key, value] of Object.entries(filters)) {
            if (value) params.append(key, value);
        }
        if (category_slug) params.append('category_slug', category_slug);
        if (brand_slug) params.append('brand_slug', brand_slug);
        return params.toString();
    };

    // Fetch products from the API when filters change
    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true); // Show loader before fetching data
            try {
                const queryString = buildQueryString();
                const response = await api.get(`/api/products/?${queryString}`);
                setProducts(response.data);
                setSortedProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false); // Hide loader after fetching
            }
        };

        fetchProducts();
    }, [filters, category_slug, brand_slug]); // Run whenever filters change

    // Handle sorting
    const handleSort = (sortType) => {
        setActiveSort(sortType);
        let sortedArray = [...products];

        switch (sortType) {
            case 'popularity':
                sortedArray.sort((a, b) => b.popularity - a.popularity);
                break;
            case 'priceLowToHigh':
                sortedArray.sort((a, b) => a.price - b.price);
                break;
            case 'priceHighToLow':
                sortedArray.sort((a, b) => b.price - a.price);
                break;
            case 'newest':
                sortedArray.sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
                break;
            default:
                break;
        }

        setSortedProducts(sortedArray);
    };

    // Update filters from ProductFilter
    const updateFilters = (updatedFilters) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }));
        // Update URL with new filters
        const updatedSearchParams = new URLSearchParams({
            ...Object.fromEntries(searchParams),
            ...updatedFilters,
        });
        setSearchParams(updatedSearchParams);
    };

    return (
        <>
            {loading && <Loader />} {/* Show loader when loading */}
            <AppFilter />
            <HoverDropdown />
            <div className='container-fluid section-margin-top section-margin-bottom product-list-container'>
                <div className='row g-0 '>
                    <div className='col-md-2 d-none d-lg-block'>
                        <div className='col-right-space'>
                            <ProductFilter
                                filters={filters}
                                onFilterChange={updateFilters}
                            />
                        </div>
                    </div>
                    <div className='col-md-10 white-section padding-20-5'>
                        <div className='container-padding '>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">Footwear</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Men's Footwear</li>
                                </ol>
                            </nav>
                            <div>
                                <p className='small-long-details'>
                                    When it comes to navigating the streets, protecting your feet is paramount. Choosing the right footwear is essential for your daily adventures. At EBAZAAR, you'll discover an extensive selection of shoes available at your fingertips.
                                </p>
                            </div>
                            <div className='products-area'>
                                <div className='custom-flex'>
                                    <h5 className='product-name h5'>Men's Footwear</h5>
                                    <span className='products-showing'>(Showing {sortedProducts.length} products)</span>
                                </div>
                            </div>
                            <div>
                                <div className='product-btn-list'>
                                    <h2>Sort By</h2>
                                    <button
                                        onClick={() => handleSort('popularity')}
                                        className={`sort-button ${activeSort === 'popularity' ? 'active' : ''}`}
                                    >
                                        Popularity
                                    </button>
                                    <button
                                        onClick={() => handleSort('priceLowToHigh')}
                                        className={`sort-button ${activeSort === 'priceLowToHigh' ? 'active' : ''}`}
                                    >
                                        Price -- Low to High
                                    </button>
                                    <button
                                        onClick={() => handleSort('priceHighToLow')}
                                        className={`sort-button ${activeSort === 'priceHighToLow' ? 'active' : ''}`}
                                    >
                                        Price -- High to Low
                                    </button>
                                    <button
                                        onClick={() => handleSort('newest')}
                                        className={`sort-button ${activeSort === 'newest' ? 'active' : ''}`}
                                    >
                                        Newest First
                                    </button>
                                </div>
                                <div className='row'>
                                    {sortedProducts.map((product) => (
                                        <div className='col-6 custom-padding col-lg-3 mb-4' key={product.id}>
                                            <div className='card' onClick={() => navigate(`/product/${product.slug}`)}>
                                                <div className='product-img'>
                                                    <img
                                                        className=' img-fluid'
                                                        src={product.thumb_300_img}
                                                        alt={`${product.brand} ${product.name}`}
                                                    />
                                                </div>
                                                <div className='card-body'>
                                                    <p className='product-title'>{product.categories.join(', ')}</p>
                                                    <p className='product-disc'>{product.name}</p>
                                                    <p>
                                                        Price: ₹{Math.floor(product.price)} <span className='orgprice'>₹{Math.floor(product.mrp)}</span> <span className='discount'>{product.discount_percentage}% Off</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductList;
