import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../axiosConfig';
import './HoverDropdown.css';

const HoverDropdown = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    api
      .get('api/categories/')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  // Helper function: Splits array into a given number of chunks (columns)
  const chunkArray = (arr, numChunks) => {
    const chunkSize = Math.ceil(arr.length / numChunks);
    let results = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      results.push(arr.slice(i, i + chunkSize));
    }
    return results;
  };

  return (
    <div className="d-none d-lg-block">
      <div className="shadow-bottom white-section">
        <ul className="categories-nav me-auto mb-2 mb-lg-0 justify-content-between w-100">
          {categories.map(category => {
            if (category.subcategories && category.subcategories.length > 0) {
              const columns = chunkArray(category.subcategories, 3);
              return (
                <li key={category.id} className="nav-item dropdown mega-dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to={`/cat/${category.slug}`}
                    id={`dropdownMenuLink-${category.id}`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {category.name}
                  </Link>
                  <div className="dropdown-menu mega-menu" aria-labelledby={`dropdownMenuLink-${category.id}`}>
                    <div className="container">
                      <div className="row">
                        {columns.map((chunk, index) => (
                          <div key={index} className="col-lg-4">
                            <ul className="list-unstyled">
                              {chunk.map(subcategory => (
                                <li key={subcategory.id}>
                                  <Link className="dropdown-item" to={`/cat/${subcategory.slug}`}>
                                    {subcategory.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li key={category.id} className="nav-item">
                  <Link className="nav-link" to={`/cat/${category.slug}`}>
                    {category.name}
                  </Link>
                </li>
              );
            }
          })}
          {/* Additional static links if needed */}
          <li className="nav-item">
            <Link className="nav-link" to="#">Flights</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#">Offer Zone</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HoverDropdown;
